<template>
	<div class="content-wrap">
			<nav-bar title="学习中心" id="navbar" style="font-size: 18px;"></nav-bar>
			<div class="wrap-bus">
				<img src="../../assets/shandong/all-city.png" alt="" @click="other" style="position: absolute; top: 29%; left: 10%; width: 80%;">
				<img src="../../assets/shandong/qsczlc.png" alt="" @click="img1()" style="position: absolute; top: 38%; left: 10%; width: 80%;">
				<!-- <img src="../../assets/shandong/other-city.png" alt="" @click="other" style="position: absolute; top: 47%; left: 10%; width: 80%;">
				<img src="../../assets/shandong/qsczlc.png" alt="" @click="img2()" style="position: absolute; top: 56.5%; left: 10%; width: 80%;"> -->
			</div>	
	</div>
</template>

<script>
import {NavBar,ImagePreview} from "vant";
	export default {
        name: "shandong-khcenter",
		components: { NavBar },
		methods: {
			img1(){
			 ImagePreview({
				images: [
					'https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/poster/cxkh.jpg',
				],
				closeable: true,
				});
			},
			other() {
				window.location.href = "https://h5.xunmeizaixian.com/index.html#/login?appNo=APP05"
			},
		}
	}

	
</script>
<style lang="less" scoped>
.content-wrap{
	width: 100%;
	height: 100vh;
	background: url('../../assets/shandong/cxkhbackGround.png') no-repeat;
	background-size: 100% 100%;
	position: relative;
	.wrap-bus{
		:nth-child(1){
			position: absolute;
			top: 125px;
			left: 0;
			width: 100%;
		}
		:nth-child(2){
			position: absolute;
			top: 350px;
			left: 0;
			width: 100%;
		}
	}
}
#navbar {
		background-color: hsl(231, 83%, 43%);
	}
	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}
	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}
</style>

